import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { ApolloFile, UserTypes } from 'src/app/shared/models/global';
import { facilityAddOns } from '@resparke/models';

export interface Facility {
  id: string;
  organisationId: string;
  name: string;
  displayName: string;
  legacyFacilityId?: string;
  passwordResetEmail?: string;
  address: Address;
  country: string;
  license: number;
  addOns: facilityAddOns[];
  residentsUploadStatus: 'PENDING' | undefined;
  subscription: MediaSubscription
  temporaryUnlockCode?: string;
  residentLimit: string;
}
export interface Address {
  address: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export interface MediaSubscription {
  provider: string;
  activatedDateUTC: string;
  startDateUTC: string;
  expiryDateUTC: string;
  userId: string;
  planCode: string;
  country: string;
  recurringFee: number;
  currency: string;
  subscriptionStartDate: string;
  status: MediaSubscriptionStatus;
  autoRenew: boolean;
}

export enum MediaSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  NONE = 'NONE',
}

export interface AddFacilityInput {
  name: string;
  legacyFacilityId?: string,
  passwordResetEmail?: string,
  addOns: facilityAddOns[],
  country: string;
  license: number;
  subscriptionStartDate?: string;
  temporaryUnlockCode?: string;
};

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }

  getQrCodeSvg() {
    const statement = `
    query getLoginQrCode {
      getLoginQrCode
    }
  `;
    return this.apiService.graphql<string>({ statement, variables: {}, type: 'getLoginQrCode' })
  }

  resetGeneralDefaultUserLog() {
    const statement = `
      mutation resetGeneralDefaultUserLogin {
        resetGeneralDefaultUserLogin
      }`;
    return this.apiService.graphql<boolean>({ statement, variables: {}, type: 'resetGeneralDefaultUserLogin' })
  }

  updateUploadStatus(input: {
    country: string; // required for the SK in the databasesq
    residentsUploadStatus?: 'PENDING';
  }) {

    const statement = `
      mutation updateResidentUploadStatus($input: UpdateResidentUploadStatusInput!) {
        updateResidentUploadStatus(input: $input)
      }
    `;
    return this.apiService
      .graphql<boolean>({ statement, variables: { input }, type: 'updateFacility' })
      .pipe(
        tap(result => {
          if (result) {
            /**
             * REPLACE THIS CACHE UPDATE WITH A GRAPHQL SUBSCRIPTION UPDATE EVENT
             */
            const facility = this.appState.get<Facility>('currentFacility');

            if (input.residentsUploadStatus) {
              facility.residentsUploadStatus = input.residentsUploadStatus;
            }
            this.appState.set('currentFacility', facility);
          }
        })
      )
  }


  getFacility(id: string): Observable<Facility> {
    const statement = `
      query getFacility($id: ID!) {
        getFacility(id: $id) {
          id
          name
          displayName
          country
          license
          legacyFacilityId
          passwordResetEmail
          residentsUploadStatus
          residentLimit
          addOns
          ${this.appState.currentUser.permissions.includes(UserTypes.SUPER_ADMIN) ? 'temporaryUnlockCode' : ''}
          subscription {
            provider
            startDateUTC
            expiryDateUTC
            status
            planCode
            subscriptionStartDate
            userId
          }
        }
      }
    `;
    return this.apiService
      .graphql<Facility>({ statement, variables: { id }, type: 'getFacility' })
      .pipe(
        tap(facility => {
          this.appState.setState('currentFacility', facility);
        })
      )

  }

  getDownloads(key: string): Observable<ApolloFile[]> {
    const statement = `
      query getFacilityDownloads($key: String!) {
        getFacilityDownloads(key: $key) {
          fileName
          path
          type
        }
      }
    `;
    return this.apiService
      .graphql<ApolloFile[]>({ statement, variables: { key }, type: 'getFacilityDownloads' })
  }
}
